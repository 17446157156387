exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-organisations-create-js": () => import("./../../../src/pages/admin/organisations/create.js" /* webpackChunkName: "component---src-pages-admin-organisations-create-js" */),
  "component---src-pages-admin-organisations-index-js": () => import("./../../../src/pages/admin/organisations/index.js" /* webpackChunkName: "component---src-pages-admin-organisations-index-js" */),
  "component---src-pages-admin-organisations-organisation-id-edit-js": () => import("./../../../src/pages/admin/organisations/[organisationId]/edit.js" /* webpackChunkName: "component---src-pages-admin-organisations-organisation-id-edit-js" */),
  "component---src-pages-admin-organisations-organisation-id-subscriptions-subscription-id-edit-js": () => import("./../../../src/pages/admin/organisations/[organisationId]/subscriptions/[subscriptionId]/edit.js" /* webpackChunkName: "component---src-pages-admin-organisations-organisation-id-subscriptions-subscription-id-edit-js" */),
  "component---src-pages-admin-resource-packs-create-js": () => import("./../../../src/pages/admin/resource-packs/create.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-create-js" */),
  "component---src-pages-admin-resource-packs-index-js": () => import("./../../../src/pages/admin/resource-packs/index.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-index-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-add-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/downloadables/add.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-add-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-create-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/downloadables/create.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-create-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-downloadable-id-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/downloadables/[downloadableId]/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-downloadable-id-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/downloadables/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-downloadables-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-lessons-add-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/lessons/add.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-lessons-add-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-lessons-create-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/lessons/create.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-lessons-create-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-lessons-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/lessons/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-lessons-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-lessons-lesson-id-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/lessons/[lessonId]/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-lessons-lesson-id-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-create-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/create.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-create-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/[sectionId]/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-lessons-add-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/[sectionId]/lessons/add.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-lessons-add-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-create-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/create.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-create-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-week-id-downloadables-add-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/[weekId]/downloadables/add.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-week-id-downloadables-add-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-week-id-edit-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/[weekId]/edit.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-week-id-edit-js" */),
  "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-week-id-lessons-add-js": () => import("./../../../src/pages/admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/[weekId]/lessons/add.js" /* webpackChunkName: "component---src-pages-admin-resource-packs-resource-pack-id-sections-section-id-weeks-week-id-lessons-add-js" */),
  "component---src-pages-admin-subscriptions-create-js": () => import("./../../../src/pages/admin/subscriptions/create.js" /* webpackChunkName: "component---src-pages-admin-subscriptions-create-js" */),
  "component---src-pages-admin-subscriptions-index-js": () => import("./../../../src/pages/admin/subscriptions/index.js" /* webpackChunkName: "component---src-pages-admin-subscriptions-index-js" */),
  "component---src-pages-admin-users-add-js": () => import("./../../../src/pages/admin/users/add.js" /* webpackChunkName: "component---src-pages-admin-users-add-js" */),
  "component---src-pages-admin-users-index-js": () => import("./../../../src/pages/admin/users/index.js" /* webpackChunkName: "component---src-pages-admin-users-index-js" */),
  "component---src-pages-admin-users-user-id-edit-js": () => import("./../../../src/pages/admin/users/[userId]/edit.js" /* webpackChunkName: "component---src-pages-admin-users-user-id-edit-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-teacher-resources-resource-id-js": () => import("./../../../src/pages/teacher-resources/[resourceId].js" /* webpackChunkName: "component---src-pages-teacher-resources-resource-id-js" */),
  "component---src-pages-teacher-resources-resource-id-sections-section-id-js": () => import("./../../../src/pages/teacher-resources/[resourceId]/sections/[sectionId].js" /* webpackChunkName: "component---src-pages-teacher-resources-resource-id-sections-section-id-js" */),
  "component---src-pages-teacher-resources-resource-id-sections-section-id-weeks-week-id-js": () => import("./../../../src/pages/teacher-resources/[resourceId]/sections/[sectionId]/weeks/[weekId].js" /* webpackChunkName: "component---src-pages-teacher-resources-resource-id-sections-section-id-weeks-week-id-js" */)
}

